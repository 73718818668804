import CurrentCountry from '@phoenix/components/CurrentCountry';
import GeoLoc from '@maison/components/GeoLoc';
import { CURRENT_COUNTRY_CHANGED, emitter } from '@phoenix/event-bus';
import store from '@phoenix/store';
import { defineAsyncComponent } from 'vue';
import createVueApp from '@phoenix/createVueApp';

document.addEventListener('DOMContentLoaded', () => {
  const currentCountry = new CurrentCountry();
  new GeoLoc(currentCountry);

  Object.assign(window, {
    currentCountry,
  });

  store.dispatch('wishlist/refreshWishlistFromCookie', {}, { root: true });

  const drawersLocalisations = document.querySelectorAll('.drawer-localisation');
  drawersLocalisations.forEach((drawerLocalisation) => {
    drawerLocalisation.addEventListener('click', () => {
      emitter.on(CURRENT_COUNTRY_CHANGED, () => window.location.reload());
    });
  });
});

if (document.getElementById('phoenix-navbar-buttons')) {
  const app = createVueApp({}, true);
  app.component(
    'CartPanelButton',
    defineAsyncComponent(() => import('../components/checkout/summary/CartPanelButton.vue'))
  );
  app.mount('#phoenix-navbar-buttons');
}

if (document.getElementById('phoenix-cart-panel')) {
  const app = createVueApp({}, true);
  app.component(
    'CartPanel',
    defineAsyncComponent(() => import('../components/checkout/summary/CartPanel.vue'))
  );
  app.mount('#phoenix-cart-panel');
}
