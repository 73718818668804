import AbstractGeoLoc from '@phoenix/components/AbstractGeoLoc';
import { Collapse, Stickyfill } from '@rawbot/roger-dubuis-styleguide';

export default class GeoLoc extends AbstractGeoLoc {
  /**
   * Show the proposition banner inside a promotion block
   */
  showPropositionBanner(): void {
    const promotionBlock = document.querySelector(this.COUNTRY_PROPOSITION_BANNER_SELECTOR);
    if (promotionBlock) {
      (<any>window).Phoenix.promotionBlockCollapsible = new Collapse(promotionBlock, {
        onHidden() {
          // Refresh the navigation position after the promotion's block closed
          Stickyfill.refreshAll();
        },
      });

      (<any>window).Phoenix.promotionBlockCollapsible.expand();
    }
  }
}
